/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { IoMdInformationCircle } from 'react-icons/io';
import { Tooltip } from '@material-tailwind/react';
import MiniLoader from '../../components/MiniLoader';
import {
	changeOrderStatusService,
	editPndOrder,
	getAllCouponService,
	getAllProductsService,
	getAllVehicleService,
	getCustomerDataForAutoFill,
	getDrivers,
	getMultiplePndCahrges,
	getPndCharges,
	getvendorDataforAutoFill,
	multiplePndOrder,
	singlePndOrder
} from '../../services/services';
import Loader from '../../components/Loader';
import AdditionalData from './AdditionalData';
import FromCustomer from './FromCustomer';
import NewPickupForm from './NewPickupForm';
import PrimaryUser from './PrimaryUser';
import ProductDetails from './ProductDetails';
import ToCustomer from './ToCustomer';
import SecureYourPackageDetails from './SecureYourPackgaeDetails';

const PndOrders = ({ editable, isOpen, toggle, isEdit, isReorder }: IPNDOrders) => {
	console.log('editable', editable);
	console.log('isReorder', isReorder);
	console.log('isEdit', isEdit);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [areaName, setAreaName] = useState<string>('');
	const [primaryArea, setPrimaryArea] = useState<string>('');
	const [primaryUserId, setPrimaryUserId] = useState<string>('');
	const [defaultUser, setDefaultUser] = useState<boolean>(false);
	const [deliveryFeeArray, setDeliveryFeeArray] = useState<any[]>([]);
	// const [venCusData, setVenCusData] = useState<any[]>([]);
	const [paymentMethod, setPaymentMethod] = useState<string>('COD');
	const [revertOrder, setRevertOrder] = useState<boolean>(false);
	const [pndId, setPndId] = useState<string>('');
	const [latData, setLatData] = useState<any>();
	const [lngData, setLngData] = useState<any>();
	const [couponId, setCouponId] = useState<string>('');
	const [vehicleName, setVehicleName] = useState<string>('');
	const [preferredVehicleId, setPreferredVehicleId] = useState<string>('');
	const [estimatedPrice, setEstimatedPrice] = useState<number>(0);
	const [distance, setDistance] = useState<number>(0);
	const [globalIndex, setGlobalIndex] = useState<number>(0);
	const [validated, setValidated] = useState<boolean>(false);
	const [pndCharge, setPndCharge] = useState<boolean>(false);
	const [quote, setQuote] = useState<any>(null);
	const [allSelectedUsers, setAllSelectedUsers] = useState<any[]>([]);
	const [hide, setHide] = useState<boolean>(false);
	const [fetchDelivery, setFetchDelivery] = useState<boolean>(false);
	const [optimiseRoute, setOptimisedRoute] = useState<boolean>(false);
	const [secureYourPackageDetails, setSecureYourPackageDetails] = useState({
		secureAmount: 0,
		secureGSTCharges: 0,
		secureCGSTCharges: 0,
		secureAmountTotalCharges: 0,
		packageSecurity: 'opt-out'
	});
	const [toCustomer, setToCustommer] = useState<any[]>([
		{
			name: '',
			mobile: '',
			lat: '',
			lon: '',
			address: '',
			codValue: '',
			landmark: '',
			pin_code: '',
			deliveryFee: 0
		}
	]);
	const [error, setError] = useState([
		{
			name: false,
			address: false,
			mobile: false
		}
	]);
	const [productFrom, setProductForm] = useState<any[]>([{ name: '', quantity: '', image: '' }]);
	const [additionalForm, setAdditionalForm] = useState<{ description: string; amount: number; location: string; lat: number }[]>([]);
	const [vendorId, setVendorId] = useState<string>('');
	const [toVendorId, setToVendorId] = useState<string>('');
	const [waitingPenalty, setWaitingPenalty] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(false);
	// React hook form
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		handleSubmit
	} = useForm({ shouldFocusError: true, mode: 'onTouched' });
	const area = useSelector((state: any) => state.navbar.selectedArea);
	const paginatedata = {
		isPaginated: 0,
		access_location: area.id,
		isFilter: 0
	};
	const queryData = new URLSearchParams(paginatedata as any).toString();

	const { data: driverDetails } = useQuery(['getAllDriverDetailss'], () => getDrivers(queryData), {
		cacheTime: 1000 * 60 * 2,
		staleTime: 1000 * 60 * 2,
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: productDetails } = useQuery('getAllProducts', getAllProductsService, {
		cacheTime: 1000 * 60 * 2,
		staleTime: 1000 * 60 * 2,
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: CouponDetailsData } = useQuery('getAllCoupon', () => getAllCouponService(queryData), {
		cacheTime: 1000 * 60 * 2,
		staleTime: 1000 * 60 * 2,
		select(selectData) {
			return selectData.data.data;
		}
	});

	// Get All Vehicle Route
	const { data: vehicleData } = useQuery(['getVehicle', queryData], () => getAllVehicleService(queryData), {
		cacheTime: 1000 * 60 * 2,
		staleTime: 1000 * 60 * 2,
		select(filteredData) {
			return filteredData.data.data;
		}
	});

	const handleClick = () => {
		if (!isLoading) {
			setIsLoading(true);
			setFetchDelivery(true);

			setTimeout(() => {
				setIsLoading(false);
				setFetchDelivery(false);
			}, 4000);
		}
	};

	useEffect(() => {
		setAllSelectedUsers(() => {
			return [...toCustomer, { name: getValues('fromName'), lat: latData }];
		});
	}, [toCustomer.length, getValues, pndCharge, latData]);
	const handleRemoveForm = (index: number, product: boolean) => {
		// console.log('index that is requested to deleted = ', index);
		if (product) {
			const filterArray = productFrom.filter((_, _index: number) => index !== _index);
			setProductForm(filterArray);
		} else {
			const filterArray = toCustomer.filter((_, _index: number) => index !== _index);
			// console.log('Filter Array = ', filterArray);
			setToCustommer(filterArray);
		}
	};
	const handleRemoveAdditionalFrom = (index: number) => {
		const filterArray = additionalForm.filter((_, _index: number) => index !== _index);
		setAdditionalForm(filterArray);
	};
	const editPndOrderMutation = useMutation(editPndOrder, {
		onSuccess() {
			queryClient.invalidateQueries('orderList');
			toast.success('Order Edited SuccessFully');
			navigate('/admin/dashboard/ordersheet');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	const placeSinglePndOrder = useMutation(singlePndOrder, {
		onSuccess() {
			queryClient.invalidateQueries('orderList');
			toast.success('Order Placed SuccessFully');
			navigate('/admin/dashboard/ordersheet');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const placeMultipleOrder = useMutation(multiplePndOrder, {
		onSuccess() {
			queryClient.invalidateQueries('orderList');
			toast.success('Order Placed SuccessFully');
			navigate('/admin/dashboard/ordersheet');
			toggle();
		},
		onError(error: any) {
			toast.error(error.response.data.message.constructor === Array ? error.response.data.message[0].msg : error.response.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});
	// console.log(edi)
	useEffect(() => {
		console.log('hello secure package', secureYourPackageDetails);
		if (isEdit) {
			setSecureYourPackageDetails({
				secureAmount: editable.secureAmount || 0,
				secureGSTCharges: editable.secureGSTCharges || 0,
				secureCGSTCharges: editable.secureCGSTCharges || 0,
				secureAmountTotalCharges: editable.secureAmountTotalCharges || 0,
				packageSecurity: editable.secureAmount > 0 ? 'opt-in' : 'opt-out'
			});
		}
	}, [editable]);

	// useEffect(() => {
	// 	// Check if it's a multiple order
	// 	if (toCustomer.length > 1) {
	// 		// Update the first order
	// 		const updatedOrders = toCustomer.map((order, index) => {
	// 			if (index === 0) {
	// 				// Add the additional amount to the first order
	// 				return {
	// 					...order,
	// 					secureYourPackageDetails: secureYourPackageDetails
	// 				};
	// 			}
	// 			return order;
	// 		});

	// 		// Update the state with the modified orders
	// 		setToCustommer(updatedOrders);
	// 	}
	// }, [toCustomer]);

	const formSubmitReq = (props: any) => {
		let client_cash: any =
			(props.fromCodAmount ? Number(props.fromCodAmount) : 0) + (toCustomer[0].codValue ? Number(toCustomer[0].codValue) : 0);

		if (toCustomer.length > 1) {
			client_cash = toCustomer.map((toCus: { name: string; codValue: string | undefined | null }, index: number) => {
				if (index === 0) {
					return {
						name: toCus.name,
						amount: (toCus.codValue ? Number(toCus.codValue) : 0) + (props.fromCodAmount ? Number(props.fromCodAmount) : 0)
					};
				}
				return { name: toCus.name, amount: toCus.codValue ? Number(toCus.codValue) : 0 };
			});
		}
		console.log({ client_cash });
		// Cod Amount Calculation
		const cod_on_pickup = 0;
		const cod_on_delivery = 0;
		// additionalForm.forEach((item: { location: string; amount: number }) => {
		// 	if (item.location === 'pickup') {
		// 		cod_on_pickup += Number(item.amount);
		// 	}
		// });
		// additionalForm.forEach((item: { location: string; amount: number }) => {
		// 	if (item.location === 'delivery') {
		// 		cod_on_delivery += Number(item.amount);
		// 	}
		// });
		// End Of Cod Amount Calculation
		toCustomer.map((x: { name: string; address: string; lat: number; lon: number; mobile: string; pin_code: string }) => {
			if (!x.name || !x.address || !x.lat || !x.lon || !x.mobile || !x.pin_code) {
				toast.error('Please Fill Form Correctly');
				setValidated(false);
			} else {
				setValidated(true);
			}
		});
		const codeDetailsArray: any =
			toCustomer.length !== 0 &&
			toCustomer.map((x: { lat: number; name: string; codValue: string; deliveryFee: number }) => {
				const codPickup = 0;
				const codDelivery =
					Number(x.codValue) > 0
						? Number(x.codValue) + Number(x.deliveryFee ? Number(x.deliveryFee) : 0)
						: Number(x.deliveryFee ? Number(x.deliveryFee) : 0);
				return {
					lat: x.lat,
					cod_on_delivery: Number(codDelivery),
					cod_on_pickup: Number(codPickup),
					additional_amounts: additionalForm
						.map((item: { lat: number; description: string; amount: number; location: string }) => {
							if (Number(item.lat) === Number(x.lat)) {
								// codPickup = item.location === 'pickup' ? Number(codPickup) + Number(item.amount) : Number(codPickup);
								// codDelivery = item.location === 'delivery' ? Number(codDelivery) + Number(item.amount) : Number(codDelivery);
								return {
									description: item.description,
									amount: Number(item.amount),
									location: item.location,
									lat: Number(item.lat),
									action: 'pay'
								};
							}
							return {
								description: '',
								amount: 0,
								location: item.location,
								lat: Number(item.lat)
							};
							// return false;
						})
						.filter((i: { lat: number; description: string; amount: number; location: string }) => Number(i.amount) !== 0)
				};
			});
		console.log({ tocustomer: toCustomer[0] });
		let del_fee_loc = editable ? editable.del_fee_loc : null;
		console.log('Value of enable cod pickuop = ', props);
		if (props.fromDeliveryFee && props.fromDeliveryFee > 0) {
			del_fee_loc = 'pickup';
		} else if (toCustomer[0].deliveryFee > 0) {
			console.log('ToCustomer = ', toCustomer[0]);
			del_fee_loc = 'delivery';
		}
		if (toCustomer[0].deliveryFee === 0 && del_fee_loc === 'delivery') {
			del_fee_loc = 'null';
		} else if (del_fee_loc === 'pickup' && props.fromDeliveryFee === 0) {
			del_fee_loc = 'null';
		}

		const fromDeliveryFee = props.fromDeliveryFee ? Number(props.fromDeliveryFee) : 0;
		const addPayload: any = {
			order_by: 'Admin',
			client_cash,
			del_fee_loc: del_fee_loc,
			defined_distance: distance,
			from_user: {
				name: props.fromName,
				mobile: props.fromMobile,
				address: props.fromAddress,
				lat: Number(latData),
				lon: Number(lngData),
				landmark: String(props.fromLandMark),
				pin_code: String(props.pinCode)
			},
			to_user:
				toCustomer.length > 1
					? [...toCustomer]
					: {
							name: toCustomer[0].name,
							mobile: toCustomer[0].mobile,
							lat: Number(toCustomer[0].lat),
							lon: Number(toCustomer[0].lon),
							address: toCustomer[0].address,
							codValue: toCustomer[0].codValue,
							pin_code: toCustomer[0].pin_code,
							landmark: toCustomer[0].landmark
					  },
			primary_user_id: defaultUser ? vendorId : primaryUserId ? primaryUserId : null,
			pnd_category_id: pndId,
			paymentDetails: {
				payment_type: paymentMethod
			},
			to_users:
				toCustomer.length > 1
					? [...toCustomer]
					: {
							name: toCustomer[0].name,
							mobile: toCustomer[0].mobile,
							lat: Number(toCustomer[0].lat),
							lon: Number(toCustomer[0].lon),
							address: toCustomer[0].address,
							landmark: toCustomer[0].landmark ?? '',
							codValue: toCustomer[0].codValue
					  },
			// driverDetails: { id: props.driver !== '' ? props.driverId : '' },
			orderDetails: {
				driver_id: props.driver !== '' ? props.driverId : '',
				date: moment(props.date).format('YYYY-MM-DD'),
				time: props.time,
				description: props.description,
				preferred_vehicle_type_id: preferredVehicleId,
				waiting_penalty: quote?.waiting_penalty ?? 0,
				penalty_desc: quote?.penalty_desc ?? '',
				optimised_route: optimiseRoute
			},
			codDetails:
				toCustomer.length === 1
					? {
							cod_on_delivery:
								Number(toCustomer[0].codValue) > 0
									? Number(toCustomer[0].codValue) +
									  Number(cod_on_delivery) +
									  (Boolean(toCustomer[0].deliveryFee) ? Number(toCustomer[0].deliveryFee) : 0)
									: Number(cod_on_delivery) + Number(toCustomer[0].deliveryFee ? Number(toCustomer[0].deliveryFee) : 0),
							cod_on_pickup:
								Number(props.fromCodAmount) > 0
									? Number(props.fromCodAmount) + Number(cod_on_pickup) + Number(props.fromDeliveryFee)
									: Number(cod_on_pickup) + Number(fromDeliveryFee),

							additional_amounts: additionalForm.map((item: { lat: number; description: string; amount: any; location: string }) => {
								return {
									lat: item.lat,
									description: item.description,
									amount: Number(item.amount),
									location: item.location,
									action: 'pay'
								};
							})
					  }
					: [
							{
								lat: Number(latData),
								cod_on_pickup:
									Number(props.fromCodAmount) > 0
										? Number(props.fromCodAmount) + Number(cod_on_pickup) + Number(props.fromDeliveryFee)
										: Number(cod_on_pickup) + Number(fromDeliveryFee),
								cod_on_delivery: 0,
								additional_amounts: additionalForm
									.map((x: { lat: number; amount: number; description: string; location: string }) => {
										if (Number(x.lat) === Number(latData)) {
											return {
												lat: Number(x.lat),
												amount: Number(x.amount),
												description: x.description,
												location: x.location,
												action: 'pay'
											};
										}
										return { lat: '', amount: 0, description: '', location: '' };
										// return false;
									})
									.filter((i: { amount: number }) => Number(i.amount) !== 0)
							},
							...codeDetailsArray

							// ...toCustomer.map((x: any) => {
							// 	return {
							// 		lat: x.lat,
							// 		cod_on_delivery: Number(x.codValue) > 0 ? Number(x.codValue) : 0,
							// 		cod_on_pickup: 0,
							// 		additional_amounts: [...additionalForm]
							// 	};
							// })
					  ],
			products: productFrom[0].name !== '' ? [...productFrom] : [],
			couponDetails: {
				id: couponId
			},
			third_party_order_id: editable.third_party_order_id ? editable.third_party_order_id : '',
			secureYourPackageDetails
		};
		// console.log({ addPayload });
		Object.keys(addPayload).forEach((key) => {
			if (toCustomer.length === 1) {
				delete addPayload['to_users'];
			} else if (toCustomer.length > 1) {
				delete addPayload['to_user'];
			}
		});
		// console.log('Add Payload = ', addPayload);
		if (isEdit && Boolean(editable) && !isReorder) {
			// Object.entries(addPayload).forEach(([key, _value]) => {
			// 	if (key === 'paymentDetails') {
			// 		return delete addPayload[key];
			// 	}
			// 	return 1;
			// });
			// console.log("editPayload==>", editPayload);
			addPayload.orderDetails = { ...addPayload.orderDetails, id: editable.id, waiting_penalty: waitingPenalty };
			const editPayload = { ...addPayload };
			console.log('editPayload==>', editPayload);

			editPndOrderMutation.mutate(editPayload);
			return 1;
		}
		if (toCustomer.length > 1) {
			validated && placeMultipleOrder.mutate(addPayload);
		} else {
			validated && placeSinglePndOrder.mutate(addPayload);
			// console.log('Add paylaod = ', addPayload);
		}
	};

	const pndCharges = useMutation(getPndCharges, {
		onSuccess(data: any) {
			setFetchDelivery(false);
			setEstimatedPrice(data.data.data.total_charges);
			setDistance(data.data.data.distance);
			setValue('estimatedPrice', data.data.data.total_charges);
			setQuote(data?.data?.data);
			setDeliveryFeeArray(() => {
				return [{ index: 0, price: Number(data.data.data.total_charges) }];
			});
		}
	});
	const bulkPndCharges = useMutation(getMultiplePndCahrges, {
		onSuccess(data: any) {
			setFetchDelivery(false);
			setDeliveryFeeArray([]);
			setEstimatedPrice(data.data.data.total_charges);
			setValue('estimatedPrice', data.data.data.total_charges);
			setDistance(data.data.data.distance);
			setQuote(data?.data?.data);
			setDeliveryFeeArray((prev: any[]) => {
				return [...prev, ...data.data.data.delivery_fee_array];
			});
		}
	});

	// Calling get Charges Api
	useEffect(() => {
		setTimeout(() => {
			const codeDetailsArray =
				allSelectedUsers.length !== 0 &&
				allSelectedUsers.map((x: { lat: number; name: string }) => {
					return {
						lat: x.lat,
						additional_amounts: additionalForm.map((item: { lat: number; description: string; amount: number; location: string }) => {
							if (Number(item.lat) === Number(x.lat) && item.description !== '') {
								return {
									description: item.description,
									amount: Number(item.amount),
									location: item.location,
									lat: Number(item.lat)
								};
							}
							return { description: '', amount: 0, location: item.location, lat: Number(item.lat) };
						})
					};
				});

			if (toCustomer[0].lat !== '' && latData && toCustomer.length === 1) {
				const payload = {
					pickup_lat: latData,
					pickup_lon: lngData,
					delivery_lat: toCustomer[0].lat,
					delivery_lon: toCustomer[0].lon,
					paymentDetails: {
						payment_type: 'COD'
					},
					pin_code: String(getValues('pinCode')),
					user_id: defaultUser ? vendorId : primaryUserId,
					codDetails: {
						additional_amounts: additionalForm.map((x: { description: string; amount: number; location: string }) => {
							return { description: x.description, amount: Number(x.amount), location: x.location };
						})
					},
					third_party_order_id: Boolean(editable) ? editable.third_party_order_id : ''
				};
				setTimeout(() => {
					if (fetchDelivery) {
						pndCharges.mutate(payload);
					}
				}, 500);
			} else if (latData && toCustomer.length > 1) {
				const payload = {
					pickup_lat: latData,
					pickup_lon: lngData,
					pin_code: String(getValues('pinCode')),
					delivery_locations: toCustomer.map((x: { lat: number; lon: number }) => {
						return { lat: x.lat, lon: x.lon };
					}),
					user_id: defaultUser ? vendorId : primaryUserId,
					paymentDetails: {
						payment_type: 'COD'
					},
					codDetails: codeDetailsArray
					// codDetails: {
					// 	additional_amounts: additionalForm.map((x: { description: string; amount: number; location: string }) => {
					// 		return { description: x.description, amount: Number(x.amount), location: x.location };
					// 	})
					// }
				};

				setTimeout(() => {
					if (fetchDelivery) {
						bulkPndCharges.mutate(payload);
					}
				}, 500);
			}
		}, 1000);
	}, [fetchDelivery]);

	// Editable UseEffct

	useEffect(() => {
		const handleAdditionForm = () => {
			setAdditionalForm([]);
			editable.codDetails.map((x: { amount: number; description: string; location: string; lat: number }) => {
				setAdditionalForm((prev: { amount: number; description: string; location: string; lat: number }[]) => {
					return [...prev, { description: x.description, amount: x.amount, location: x.location, lat: x.lat }];
				});
			});
		};
		const handleEditableProduct = () => {
			setProductForm([]);
			editable.products.map((x: { name: string; quantity: number; image: string }) => {
				setProductForm((prev: { name: string; quantity: number; image: string }[]) => {
					return [...prev, { name: x.name, quantity: x.quantity, image: x.image === 'None' ? '' : x.image }];
				});
			});
		};
		if (Boolean(editable)) {
			const condPickup =
				editable.del_fee_loc && editable.del_fee_loc === 'pickup'
					? Number(editable.cod_on_pickup) - Number(Number(editable.cod_value) + Number(editable.waiting_penalty))
					: Number(editable.cod_on_pickup);
			const codDelivery =
				editable.del_fee_loc && editable.del_fee_loc === 'delivery'
					? Number(editable.cod_on_delivery) - Number(Number(editable.cod_value) + Number(editable.waiting_penalty))
					: Number(editable.cod_on_delivery);
			setPndId(editable.pnd_category_id);
			setValue('fromName', editable.from_name);
			setValue('fromMobile', editable.from_mobile);
			setValue('fromAddress', editable.from_address);
			setValue('fromLat', Number(editable.from_lat));
			setValue('fromLandMark', editable.from_landmark);
			setLatData(editable.from_lat);
			setLngData(editable.from_lon);
			setValue('fromLon', Number(editable.from_lon));
			setValue('fromEmail', editable.from_email);
			setValue('pinCode', Number(editable.from_pin_code));
			setValue('primaryAddress', editable.primary_address);
			setValue('primaryName', editable.primary_name);
			setValue('primaryEmail', editable.primary_email);
			setValue('primaryLat', Number(editable.primary_lat));
			setValue('primaryLon', Number(editable.primary_lon));
			setValue('primaryMobile', editable.primary_mobile);
			!isReorder && setValue('date', new Date(editable.date));
			!isReorder && setValue('time', moment(editable.time, 'HH:mm').format('HH:mm:ss a'));
			setValue('estimatedPrice', editable.payable_amount);
			!isReorder && setValue('driverId', editable.driver_id);
			!isReorder && setValue('driver', editable.driver_name);
			!isReorder && setValue('fromCodAmount', condPickup > 0 ? condPickup : 0);
			setValue('fromCod_delivery_fee', editable?.del_fee_loc === 'pickup' ? true : false);
			setValue('fromDeliveryFee', editable?.del_fee_loc === 'pickup' ? editable.cod_value : 0);
			setAreaName(editable.from_address);
			setVehicleName(editable.pref_vehicle);
			setPrimaryArea(editable.primary_address);
			toCustomer[0].name = editable.to_name;
			toCustomer[0].address = editable.to_address;
			toCustomer[0].mobile = editable.to_mobile;
			toCustomer[0].lat = Number(editable.to_lat);
			toCustomer[0].lon = Number(editable.to_lon);
			toCustomer[0].codValue = !isReorder ? (codDelivery > 0 ? codDelivery : 0) : 0;
			toCustomer[0].pin_code = editable.to_pin_code;
			toCustomer[0].landmark = editable.to_landmark;
			toCustomer[0].deliveryFee = editable?.del_fee_loc === 'delivery' ? editable.cod_value : 0;
			Boolean(editable.codDetails) && editable.codDetails.length !== 0 && handleAdditionForm();
			setPrimaryUserId(editable.primary_user_id);
			Boolean(editable.products) && editable.products.length > 0 && handleEditableProduct();
			editable.payment_type && setPaymentMethod(editable.payment_type);
			setWaitingPenalty(Number(editable.waiting_penalty));
			setDeliveryFeeArray(() => {
				return [{ index: 0, price: Number(editable.cod_value) }];
			});
			// console.log('editable =', editable);
		}
	}, [editable]);
	useEffect(() => {
		// refetchVendor();
		// refetchCustomer();
		return () => {
			setRevertOrder(false);
		};
	}, []);

	// Function Thata Place A revert Order
	let delivered = false;
	const changeOrderStatusMutation = useMutation(changeOrderStatusService, {
		onSuccess() {
			delivered = true;
			console.log('Order Cancelled Successfully');
		},
		onError(error: any) {
			console.log(error);
		}
	});
	const placeRevertOrder = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Current Order Will Be Cancelled and New Revert Order Will Be Placed',
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#856544',
			cancelButtonColor: 'gray',
			confirmButtonText: 'Yes, Place it!'
		}).then((result: any) => {
			if (result.isConfirmed) {
				setRevertOrder(true);
				// Revert Order Payload
				if (Boolean(editable)) {
					const revertPayload = {
						from_user: {
							name: toCustomer[0].name,
							mobile: toCustomer[0].mobile,
							lat: Number(toCustomer[0].lat),
							lon: Number(toCustomer[0].lon),
							address: toCustomer[0].address,
							codValue: toCustomer[0].codValue,
							pin_code: toCustomer[0].pin_code
						},
						to_user: {
							name: editable.from_name,
							mobile: editable.from_mobile,
							address: editable.from_address,
							lat: Number(latData),
							lon: Number(lngData),
							pin_code: String(editable.from_pin_code)
						},
						driverDetails: { id: editable.driver !== '' ? editable.driverId : '' },
						orderDetails: {
							date: moment(new Date()).format('YYYY-MM-DD'),
							time: moment().add(2, 'minutes').format('HH:mm'),
							description: editable.description
						},
						codDetails: Boolean(editable.codDetails) ? { ...editable.codDetails[0] } : {},
						products: productFrom[0].name !== '' ? [...productFrom] : [],
						couponDetails: {
							id: couponId
						},
						primary_user_id: editable.primary_user_id,
						pnd_category_id: editable.pnd_category_id,
						paymentDetails: {
							payment_type: 'COD'
						}
					};
					// console.log('Revert paylaod = ', revertPayload);
					if (editable.order_status !== 'Delivered') {
						changeOrderStatusMutation.mutate({ id: editable.id, status: 'Delivered' });
					} else {
						delivered = true;
					}
					setTimeout(() => {
						delivered
							? placeSinglePndOrder.mutate(revertPayload)
							: toast.error("Can't set the current order Delivered please set it to deliver manually");
					}, 1000);
				} else {
					toast.error('Something is wrong try after sometime!');
				}
			}
		});

		// End of Revert Order Payload
	};
	// console.log('Editable = ', editable);

	// Debouncing Code
	// Api Calling of vendor and customer and debouncing
	const debounce = useRef<NodeJS.Timeout>();
	const [queryTerm, setQueryTerm] = useState<string>('');
	const debouncingData: any = {
		// isFilter: queryTerm === '' ? 0 : 1,
		// isPaginated: queryTerm === '' ? 1 : 0,
		// pageId: 1,
		// pageLimit: 50,
		orderAutoFill: 1,
		name: queryTerm,
		access_location: area.id
	};
	Object.entries(debouncingData).forEach(([key, _value]) => {
		if (queryTerm !== '') {
			key === 'isPaginated' && delete debouncingData[key];
			key === 'pageId' && delete debouncingData[key];
			key === 'pageLimit' && delete debouncingData[key];
		}
	});
	const deboucingParams = new URLSearchParams(debouncingData).toString();
	const { data: vendorData, refetch: refetchVendor } = useQuery(['getAllVendor1'], () => getvendorDataforAutoFill(deboucingParams), {
		enabled: false,
		select(selectData) {
			return selectData.data.data;
		}
	});
	const { data: customerData, refetch: refetchCustomer } = useQuery(['getAllCustomer'], () => getCustomerDataForAutoFill(deboucingParams), {
		enabled: false,
		select(selectData) {
			return selectData.data?.data;
			// return queryTerm === '' ? selectData.data?.data : selectData.data?.user;
		}
	});
	useEffect(() => {
		clearInterval(debounce.current);
		clearTimeout(debounce.current);
		debounce.current = setTimeout(() => {
			refetchVendor();
			refetchCustomer();
		}, 1000);
	}, [queryTerm]);

	useEffect(() => {
		if (toCustomer.length > 1) {
			setHide(true);
		} else {
			setHide(false);
		}
	}, [hide, toCustomer]);

	// console.log('Editabe of order = ', editable);
	return (
		<div>
			<form onSubmit={handleSubmit(formSubmitReq)}>
				<div>
					<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 ">Pickup Details </p>

					<FromCustomer
						register={register}
						setQueryTerm={setQueryTerm}
						vendorData={Boolean(vendorData) && Boolean(customerData) ? [...vendorData, ...customerData] : []}
						setValue={setValue}
						areaName={areaName}
						setAreaName={setAreaName}
						editable={editable}
						errors={errors}
						isOpen={isOpen}
						latData={latData}
						lngData={lngData}
						setLatData={setLatData}
						setLngData={setLngData}
						setVendorId={setVendorId}
						deliveryFeeArray={deliveryFeeArray}
						getValues={getValues}
						hide={hide}
						paymentMethod={paymentMethod}
						estimatedPrice={estimatedPrice}
					/>

					<label className="flex items-center">
						<input
							// disabled={vendorId === '' ? true : false}
							type="checkbox"
							name=""
							id=""
							onChange={(e: any) => (e.target.checked ? setDefaultUser(true) : setDefaultUser(false))}
						/>
						<span className="ml-2">Billing User</span>
					</label>
				</div>
				{paymentMethod === 'CREDIT' && (
					<div className="relative">
						<Tooltip content="Delivery Fee is paid" className="absolute  left-0">
							<small className="text-red-700 font-bold flex items-center text-2xl w-fit">
								<IoMdInformationCircle className="mr-1 animate-bounce text-red-600 font-bold" size={30} /> Note - You are placing order with{' '}
								<strong className="font-extrabold">&nbsp; CREDIT PAYMENT MODE</strong>
							</small>
						</Tooltip>
					</div>
				)}
				<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 ">Delivery Details</p>
				{toCustomer.map((_, index: number) => {
					return (
						<>
							{index > 0 && (
								<FaTrash
									onClick={() => handleRemoveForm(index, false)}
									size={20}
									className="text-red-400 hover:text-red-600 cursor-pointer"
								/>
							)}{' '}
							<ToCustomer
								isOpen={isOpen}
								register={register}
								setQueryTerm={setQueryTerm}
								vendorData={Boolean(vendorData) && Boolean(customerData) ? [...vendorData, ...customerData] : []}
								// vendorData={Boolean(vendorData) && Boolean(customerData) ? [...vendorData, ...customerData] : []}
								index={index}
								setToVendorId={setToVendorId}
								setToCustommer={setToCustommer}
								toCustomer={toCustomer}
								setGlobalIndex={setGlobalIndex}
								setPndCharge={setPndCharge}
								error={error}
								setError={setError}
								setValue={setValue}
								getValues={getValues}
								deliveryFeeArray={deliveryFeeArray}
								editable={editable}
								paymentMethod={paymentMethod}
							/>
						</>
					);
				})}
				{!isEdit && (
					<div className={toCustomer.length >= 30 ? 'hidden' : 'flex justify-center'}>
						<button
							type="button"
							className="w-36 h-12 text-white flex items-center justify-center  text-xl rounded-md  bg-secondary"
							onClick={() => {
								setError((prev: any) => {
									return [...prev, { name: false, address: false, mobile: false }];
								});
								setToCustommer((prev: any) => {
									return [
										...prev,
										{ name: '', mobile: '', lat: '', lon: '', address: '', codValue: '', pin_code: '', landmark: '', deliveryFee: 0 }
									];
								});
							}}
						>
							Add More
						</button>
					</div>
				)}
				{!defaultUser && !isEdit && (
					<div className="mt-6">
						<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> Billing User</p>
						<PrimaryUser
							register={register}
							setValue={setValue}
							errors={errors}
							setQueryTerm={setQueryTerm}
							vendorData={Boolean(vendorData) && Boolean(customerData) ? [...vendorData, ...customerData] : []}
							// vendorData={Boolean(vendorData) && Boolean(customerData) ? [...vendorData, ...customerData] : []}
							isOpen={isOpen}
							areaName={primaryArea}
							setAreaName={setPrimaryArea}
							setVendorId={setPrimaryUserId}
						/>
					</div>
				)}

				<div className="mt-6">
					<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
					<NewPickupForm
						register={register}
						setValue={setValue}
						errors={errors}
						driverData={driverDetails}
						setPndId={setPndId}
						pndId={pndId}
						estimatedPrice={estimatedPrice}
						distance={distance}
						editable={Boolean(editable) ? editable : undefined}
					/>
					{isEdit && (
						<div className="flex flex-col justify-center mb-2 md:mb-0 w-[35%] ">
							<label className="text-gray-500 mb-1 ">Waiting Penalty </label>
							<input
								type="number"
								className="formField"
								value={waitingPenalty}
								onChange={(e) => setWaitingPenalty(Number(e.target.value))}
							/>
						</div>
					)}
				</div>
				{/* // Fetch Delivery Fee  */}
				<div className="flex justify-center my-4 w-full relative right-0 " onClick={handleClick}>
					<button
						type="button"
						disabled={isLoading}
						className="w-48 h-12 text-white flex items-center justify-center  text-xl rounded-md  bg-secondary"
					>
						{isLoading || pndCharges.isLoading || bulkPndCharges.isLoading ? <MiniLoader color="white" /> : 'Fetch Delivery Fee'}
					</button>
				</div>
				<div className="mt-6">
					<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 flex items-center ">
						Products <span className="text-xs">(optional)</span>{' '}
					</p>
					{productFrom.map((_, index: number) => {
						return (
							<>
								{index > 0 && (
									<FaTrash
										onClick={() => handleRemoveForm(index, true)}
										size={20}
										className="text-red-400 hover:text-red-600 cursor-pointer mb-2 "
									/>
								)}
								<ProductDetails
									products={productDetails}
									id={vendorId}
									setProductForm={setProductForm}
									index={index}
									productFrom={productFrom}
								/>
							</>
						);
					})}
					<div className="flex justify-center my-4">
						<button
							type="button"
							className="w-36 h-12 text-white flex items-center justify-center  text-xl rounded-md  bg-secondary"
							onClick={() =>
								setProductForm((prev: any) => {
									return [...prev, { name: '', quantity: '', image: '' }];
								})
							}
						>
							Add More
						</button>
					</div>
				</div>
				<div className="mt-6">
					<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
					{Boolean(additionalForm) &&
						additionalForm.map((_, index: number) => {
							return (
								<>
									<FaTrash
										onClick={() => handleRemoveAdditionalFrom(index)}
										size={20}
										className="text-red-400 hover:text-red-600 cursor-pointer mb-2 my-2"
									/>

									<AdditionalData
										additionalForm={additionalForm}
										setAdditionalForm={setAdditionalForm}
										index={index}
										isEdit={isEdit}
										allSelectedUser={allSelectedUsers}
										setPndCharge={setPndCharge}
									/>
								</>
							);
						})}
					<div className="flex justify-center my-4">
						<button
							type="button"
							className="w-fit h-12 p-4 text-white flex items-center justify-center  text-xl rounded-md  bg-secondary"
							onClick={() =>
								setAdditionalForm((prev: any) => {
									return [...prev, { description: '', amount: '', location: 'pickup', lat: '' }];
								})
							}
						>
							Add Task
						</button>
					</div>
				</div>
				<div className="mt-6">
					<div className="grid grid-cols-1 md:grid-cols-3">
						<div>
							<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
							<p className="text-gray-400">Coupon Code</p>
							<select name="" id="" className="formField " onChange={(e: any) => setCouponId(e.target.value)}>
								<option value={''}>Choose...</option>
								{Boolean(CouponDetailsData) &&
									CouponDetailsData.filter((x: any) => x.is_active === 1).map((x: any, index: number) => {
										return (
											<option value={x.id} key={index}>
												{x.code}
											</option>
										);
									})}
							</select>
						</div>
						{/* // Vehicle selection  */}
						<div className=" ml-4">
							<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
							<p className="text-gray-400">Select preferred vehicle</p>
							<select
								name=""
								id=""
								className="formField "
								onChange={(e: any) => {
									setVehicleName(e.target.value);
									const preferredId = vehicleData.find((x: { name: string }) => x.name === e.target.value);
									setPreferredVehicleId(preferredId?.id);
								}}
								value={vehicleName}
							>
								<option value={''}>Choose...</option>
								{Boolean(vehicleData) &&
									vehicleData.map((x: any, index: number) => {
										return (
											<option value={x.name} key={index}>
												{x.name}
											</option>
										);
									})}
							</select>
						</div>
						{toCustomer.length > 1 && (
							<div className=" ml-4">
								<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4"> </p>
								<p className="text-gray-400">Optimised Route</p>
								<div className=" mt-2">
									<label className="switchToggle">
										<input type="checkbox" className="ml-4" checked={optimiseRoute} onChange={() => setOptimisedRoute((prev) => !prev)} />
										<span className="slider round" />
									</label>
								</div>
							</div>
						)}
						{/* // Select Payment Method  */}
						{/* <div className=" ml-4">
							<p className="text-xl border-b-red-500 border-b-2 w-[10%] mb-4 "> </p>
							<p className="text-gray-400">Select Payment Method</p>
							<select
								name=""
								id=""
								className="formField "
								onChange={(e: any) => {
									setPaymentMethod(e.target.value);
								}}
								value={paymentMethod}
								disabled={editable ? (editable.payment_type === 'Online' ? true : false) : false}
								required
							>
								<option value={''}>Choose...</option>
								<option value={'COD'}>Cash on delivery</option>
								<option value={'CREDIT'}>
									Credit <small>(Delivery fee will be excluded)</small>
								</option>
							</select>
						</div> */}
					</div>
				</div>
				{!isEdit && !isReorder && (
					<SecureYourPackageDetails
						initialSecureAmount={secureYourPackageDetails.secureAmount}
						initialSecureGSTCharges={secureYourPackageDetails.secureGSTCharges}
						initialSecureCGSTCharges={secureYourPackageDetails.secureCGSTCharges}
						initialSecureAmountTotalCharges={secureYourPackageDetails.secureAmountTotalCharges}
						initialPackageSecurity={secureYourPackageDetails.packageSecurity}
						onPackageDetailsChange={(updatedDetail: any) => setSecureYourPackageDetails(updatedDetail)}
					/>
				)}

				{isEdit && Boolean(editable) && !isReorder && (
					<SecureYourPackageDetails
						initialSecureAmount={editable.secureAmount || 0}
						initialSecureGSTCharges={editable.secureGSTCharges || 0}
						initialSecureCGSTCharges={editable.secureCGSTCharges || 0}
						initialSecureAmountTotalCharges={editable.secureAmountTotalCharges || 0}
						initialPackageSecurity={editable.secureAmount > 0 ? 'opt-in' : 'opt-out'}
						onPackageDetailsChange={(updatedDetails) => {
							console.log('Updated Secure Details:', updatedDetails);
							setSecureYourPackageDetails(updatedDetails); // Update state
						}}
					/>
				)}

				{isReorder && !isEdit && (
					<SecureYourPackageDetails
						initialSecureAmount={editable.secureAmount || 0}
						initialSecureGSTCharges={editable.secureGSTCharges || 0}
						initialSecureCGSTCharges={editable.secureCGSTCharges || 0}
						initialSecureAmountTotalCharges={editable.secureAmountTotalCharges || 0}
						initialPackageSecurity={editable.secureAmount > 0 ? 'opt-in' : 'opt-out'}
						onPackageDetailsChange={(updatedDetails) => {
							console.log('Updated Secure Details (Reorder):', updatedDetails);
							setSecureYourPackageDetails(updatedDetails); // Update state
						}}
					/>
				)}

				<div className="flex  items-center  mt-6 mb-12  ">
					<button
						type="submit"
						disabled={
							placeMultipleOrder.isLoading || (placeSinglePndOrder.isLoading && !revertOrder) || editPndOrderMutation.isLoading
								? true
								: false
						}
						className="hover:bg-[#4c3f34] w-36 h-12 text-white flex items-center justify-center text-xl rounded-md  bg-secondary"
					>
						{placeMultipleOrder.isLoading || (placeSinglePndOrder.isLoading && !revertOrder) || editPndOrderMutation.isLoading ? (
							<MiniLoader />
						) : (
							'Submit'
						)}
						{/* {addMutation.isLoading || editMutation.isLoading ? <MiniLoader /> : 'Submit'} */}
					</button>
					{isEdit && (
						<div className="flex items-center">
							{' '}
							<p className="ml-4">Or</p>
							<p
								onClick={placeRevertOrder}
								className="hover:bg-[#4c3f34] cursor-pointer ml-4 p-3  h-12 text-white flex items-center justify-center text-xl rounded-md w-44   bg-secondary"
							>
								{placeSinglePndOrder.isLoading && revertOrder ? <MiniLoader /> : 'Revert Order'}
							</p>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

interface IPNDOrders {
	isOpen: boolean;
	isEdit: boolean;
	editable?: any;
	toggle: any;
	isReorder?: boolean;
}
export default PndOrders;
// function from_email(arg0: string, from_email: any) {
// 	throw new Error('Function not implemented.');
// }

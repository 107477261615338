import React, { useEffect, useState } from 'react';

interface SecureYourPackageDetailsProps {
	initialSecureAmount?: number;
	initialSecureAmountTotalCharges?: number;
	initialSecureGSTCharges?: number;
	initialSecureCGSTCharges?: number;
	initialPackageSecurity?: string; // Default: 'opt-out' or 'opt-in'
	onPackageDetailsChange: (details: {
		secureAmount: number;
		secureGSTCharges: number;
		secureCGSTCharges: number;
		secureAmountTotalCharges: number;
		packageSecurity: string;
	}) => void; // Callback to pass updated values to parent
}

const SecureYourPackageDetails: React.FC<SecureYourPackageDetailsProps> = ({
	initialSecureAmount = 0,
	initialSecureGSTCharges,
	initialSecureCGSTCharges,
	initialSecureAmountTotalCharges = 0,
	initialPackageSecurity = 'opt-out',
	onPackageDetailsChange
}) => {
	console.log(
		'PackageDetails',
		initialSecureAmount,
		initialSecureGSTCharges,
		initialSecureCGSTCharges,
		initialSecureAmountTotalCharges,
		initialPackageSecurity
	);
	const [packageDetails, setPackageDetails] = useState({
		secureAmount: initialSecureAmount,
		secureGSTCharges: initialSecureGSTCharges ?? 0,
		secureCGSTCharges: initialSecureCGSTCharges ?? 0,
		secureAmountTotalCharges: initialSecureAmountTotalCharges,
		packageSecurity: initialPackageSecurity,
		error: null as string | null
	});

	const calculateCharges = (value: number) => {
		const baseCharge = (value * 5) / 100;
		const gstCharges = (baseCharge * 9) / 100;
		const cgstCharges = (baseCharge * 9) / 100;
		return {
			totalCharges: Math.ceil(baseCharge + gstCharges + cgstCharges),
			gstCharges: Math.ceil(gstCharges),
			cgstCharges: Math.ceil(cgstCharges)
		};
	};
	useEffect(() => {
		setPackageDetails({
			secureAmount: initialSecureAmount,
			secureGSTCharges: initialSecureGSTCharges ?? 0,
			secureCGSTCharges: initialSecureCGSTCharges ?? 0,
			secureAmountTotalCharges: initialSecureAmountTotalCharges,
			packageSecurity: initialPackageSecurity,
			error: null
		});
	}, [initialSecureAmount, initialSecureGSTCharges, initialSecureCGSTCharges, initialSecureAmountTotalCharges, initialPackageSecurity]);

	const handlePackageSecurityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setPackageDetails((prev: any) => ({
			...prev,
			packageSecurity: value,
			secureAmount: value === 'opt-out' ? '' : prev.secureAmount,
			secureGSTCharges: 0,
			secureCGSTCharges: 0,
			secureAmountTotalCharges: 0,
			error: null
		}));

		if (value === 'opt-out') {
			onPackageDetailsChange({
				secureAmount: 0,
				secureGSTCharges: 0,
				secureCGSTCharges: 0,
				secureAmountTotalCharges: 0,
				packageSecurity: 'opt-out'
			});
		}
	};

	const handlePackageValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseFloat(event.target.value);

		if (isNaN(value) || value <= 0) {
			setPackageDetails((prev: any) => ({
				...prev,
				secureAmount: '',
				secureGSTCharges: 0,
				secureCGSTCharges: 0,
				secureAmountTotalCharges: 0,
				error: 'Enter a valid package value greater than 0.'
			}));
			return;
		}

		if (value > 100000) {
			const adjustedValue = 100000;
			const charges = calculateCharges(adjustedValue);
			setPackageDetails({
				secureAmount: adjustedValue,
				secureGSTCharges: charges.gstCharges,
				secureCGSTCharges: charges.cgstCharges,
				secureAmountTotalCharges: charges.totalCharges,
				packageSecurity: 'opt-in',
				error: 'Package value cannot exceed ₹1,00,000. Value adjusted to ₹1,00,000.'
			});

			onPackageDetailsChange({
				secureAmount: adjustedValue,
				secureGSTCharges: charges.gstCharges,
				secureCGSTCharges: charges.cgstCharges,
				secureAmountTotalCharges: charges.totalCharges,
				packageSecurity: 'opt-in'
			});

			return;
		}

		const charges = calculateCharges(value);
		setPackageDetails({
			secureAmount: value,
			secureGSTCharges: charges.gstCharges,
			secureCGSTCharges: charges.cgstCharges,
			secureAmountTotalCharges: charges.totalCharges,
			packageSecurity: 'opt-in',
			error: null
		});

		onPackageDetailsChange({
			secureAmount: value,
			secureGSTCharges: charges.gstCharges,
			secureCGSTCharges: charges.cgstCharges,
			secureAmountTotalCharges: charges.totalCharges,
			packageSecurity: 'opt-in'
		});
	};

	return (
		<div className="mt-6 rounded-xl bg-gradient-to-r from-[#F9E3CB] to-[#f4f3f1] p-6 shadow-lg">
			<h3 className="text-lg font-bold mb-4 text-gray-800">Secure Your Package</h3>
			<fieldset>
				<legend className="sr-only">Package Security Options</legend>
				<div className="flex flex-col space-y-4">
					<label className="flex items-center space-x-3">
						<input
							type="radio"
							name="packageSecurity"
							value="opt-in"
							checked={packageDetails.packageSecurity === 'opt-in'}
							onChange={handlePackageSecurityChange}
							className="form-radio text-primary"
							aria-label="Opt for Package Security"
						/>
						<span className="text-sm">Opt for Package Security</span>
					</label>
					<label className="flex items-center space-x-3">
						<input
							type="radio"
							name="packageSecurity"
							value="opt-out"
							checked={packageDetails.packageSecurity === 'opt-out'}
							onChange={handlePackageSecurityChange}
							className="form-radio text-primary"
							aria-label="Proceed without Package Security"
						/>
						<span className="text-sm">Proceed without Package Security</span>
					</label>
				</div>
			</fieldset>

			{packageDetails.packageSecurity === 'opt-in' && (
				<div className="mt-4 flex flex-col space-y-2">
					<input
						type="number"
						value={packageDetails.secureAmount}
						onChange={handlePackageValueChange}
						placeholder="Enter Package Value"
						className={`w-full p-2 border rounded-md shadow-sm focus:outline-none ${
							packageDetails.error ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary'
						}`}
						aria-label="Enter Package Value"
					/>
					{packageDetails.error && <span className="text-red-500 text-sm">{packageDetails.error}</span>}
					{!packageDetails.error && packageDetails.secureAmount && (
						<span className="text-sm text-gray-700 font-medium">
							₹{packageDetails.secureAmountTotalCharges} : Additional charges for securing the package
						</span>
					)}
				</div>
			)}

			<p className="mt-4 text-xs text-gray-700">
				We offer standard Insurance coverage up to 8 times the delivery fee or a maximum of ₹500, whichever lower, ensuring peace of mind
				for your important or fragile packages. In case of loss or damage during delivery, you can claim the declared value of your package.
				For added security, opt for our Package Security, which provides coverage for declared values. A nominal fee of 5% + GST of the
				declared value (in addition to the delivery cost) applies to be paid during order placement.
				<strong>Note:</strong> Terms and conditions apply.
			</p>
		</div>
	);
};

export default SecureYourPackageDetails;
